import React from "react"
import "../wdyr"
import { navigate } from "gatsby"
import { isLoggedIn } from "../services/auth"
import SEO from "../components/seo"
import Agenda from "../components/agenda"
import { useQuery } from "react-query"

const AgendaPage = () => {
  const {
    isLoading: agendaDataIsLoading,
    error: agendaDataLoadingError,
    data: agendaData = {
      result: {
        agenda: {
          title: "Agenda",
          days: [],
        },
        presentations: [],
        activeTabIndex: 0,
      },
    },
  } = useQuery("agenda", () =>
    fetch(`${process.env.API_URL}/${process.env.EVENT_ID}/agenda`).then(res =>
      res.json()
    )
  )
  if (typeof window !== "undefined" && !isLoggedIn()) {
    navigate("/")
    return null
  }
  return (
    <>
      <div className="width-full page">
        <SEO title="Agenda" />
        <h2 className="d-xs-none d-sm-block text-size-h3 font-primary-bold mb-30 color-secondary-1">
          {agendaData?.result?.agenda.agenda_title}
        </h2>
        {!!agendaData?.result?.agenda &&
          agendaData.result.agenda.agenda_description && (
            <div
              dangerouslySetInnerHTML={{
                __html: agendaData.result.agenda.agenda_description,
              }}
            />
          )}
        {!agendaDataIsLoading && (
          <Agenda
          days={agendaData?.result?.agenda.days ? agendaData?.result?.agenda.days : []}
          presentations={agendaData?.result?.presentations}
          startIndex={agendaData?.result?.activeTabIndex}
          showBtn={true}
          />
        )}
        {agendaDataIsLoading && <div>Loading...</div>}
        {agendaDataLoadingError && (
          <div>An Error Occured loading the data. Please refresh</div>
        )}
      </div>
   </>
  )
}

export default React.memo(AgendaPage)
